@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  height: 100vh;
  background-color: #161616;
  max-width: 72rem;
  width: 1112px;
  font-family: 'Montserrat','Arial';
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body.no-max-width {
  max-width: none;
}

.lock-scroll {
  overflow: hidden;
}

code {
  font-family: 'Montserrat','Arial';
}

@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animate-fill {
  animation: fill 2s infinite;
}

.linkvisited {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  color: var(--tw-color-secondary);
  text-underline-offset: 8px;
}